import { Calendar as CalendarIcon } from 'lucide-react';
import { Calendar } from 'src/components/shad-base/calendar';

import { cn } from '@/lib/utils';
import { Button } from 'src/components/shad-base/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';
import { fLocalDateShort } from 'src/utils/format';
import { useState } from 'react';

export function DatePicker({
  date,
  setDate,
  minDate,
  maxDate,
  disabled = false,
  placeholder = 'Select a date',
  renderCustomAnchorEl = null,
  size = 'default',
  label,
  required = false
}: {
  date: Date;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  setDate: (date: Date) => void;
  placeholder?: string;
  renderCustomAnchorEl?: () => React.ReactNode;
  size?: 'sm' | 'default' | 'lg' | 'xl' | 'icon';
  required?: boolean;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={disabled}>
        {renderCustomAnchorEl ? (
          renderCustomAnchorEl()
        ) : (
          <Button
            variant={'outline'}
            className={cn(
              'w-full min-w-[280px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
            size={size}
            disabled={disabled}
          >
            <div className="flex flex-nowrap items-center">
              {required && (
                <p className="text-destructive">*&nbsp;</p>
              )}
              {label ? (
                <p className="body2 mr-sm">{label}</p>
              ) : (
                <CalendarIcon className="mr-2 h-4 w-4" />
              )}
              {date ? (
                fLocalDateShort(date)
              ) : (
                <span className="body2 text-muted">
                  {placeholder || 'Select a date'}
                </span>
              )}
            </div>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          minDate={minDate}
          maxDate={maxDate}
          selected={date}
          captionLayout="dropdown-buttons"
          onSelect={(date) => {
            setDate(date);
            setOpen(false);
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
